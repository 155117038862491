import { cn } from "@/lib/utils";
import { router } from "@inertiajs/react";
import { state } from "@state/store";
import { fabric } from "fabric";
import { reduce } from 'lodash';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import { JSONPostRequest } from "./JSONRequest";


const generateId = (length = 16) => {
    return `kirby${uuidv4().replace('-', '')}`.substring(0, length);
}

const Toast = Swal.mixin({
    toast: true,
    position: 'top-right',
    iconColor: 'white',
    customClass: {
        popup: 'colored-toast',
        container: 'swal2-container'
    },
    showConfirmButton: false,
    timer: 1800,
    timerProgressBar: true,
})

const toast = async (message = "", type = "success") => {
    await Toast.fire({
        html: message || 'Success',
        icon: ['success', 'error', 'warning', 'info', 'question'].includes(type) ? type : 'success',
    })
}

const lang = (string) => {
    //todo lang implementation
    return typeof string === 'string' ? string.trim() : "";
}

const stripslashes = (value) => {
    return !value ? '' : value.replace(/\\(.)/mg, "$1");
}

const canvasToMockupPreview = async (stage, canvasRef, bgColor, multiplier) => {
    return new Promise((resolve, reject) => {
        canvasRef.current.discardActiveObject().renderAll();
        let jsonFile = canvasRef.current.toJSON();
        delete jsonFile.clipPath;

        let canvas = document.createElement("canvas");
        const gradCanvas = new fabric.Canvas(canvas, { width: stage.product_width, height: stage.product_height });
        gradCanvas.loadFromJSON(JSON.stringify(jsonFile), function () {
            gradCanvas.item(0).set({ selectable: false, stroke: 'transparent' })
            gradCanvas.renderAll();

            fabric.Image.fromURL(stage.image, function (image) {
                image.set({
                    originX: 'center',
                    originY: 'center',
                })
                gradCanvas.setBackgroundColor(bgColor)
                gradCanvas.add(image);
                gradCanvas.centerObject(image)
                image.scaleToWidth(stage.product_width);
                image.scaleToHeight(stage.product_height);
                gradCanvas.discardActiveObject().renderAll();

                setTimeout(() => {
                    resolve(gradCanvas.toDataURL({
                        format: 'png',
                        quality: 1,
                        multiplier: multiplier || 300 / 96
                    }));
                }, 1000)
            }, {
                crossOrigin: 'anonymous'
            })
        })
    });
};


const canvasesToJson = async (canvases, productId, bgColor) => {
    return new Promise((resolve, reject) => {
        let canvasesJson = canvases.map((item) => {
            return item.ref.current
                ? {
                    [item.key]: item.ref.current.toJSON([
                        "id",
                        "title",
                        "objectType",
                        "name",
                        'selectable'
                    ])
                }
                : { [item.key]: null };
        });

        canvasesJson.push({ product_id: productId });
        canvasesJson.push({ product_color: bgColor });

        resolve(reduce(
            canvasesJson,
            (result, obj) => {
                const key = Object.keys(obj)[0];
                const value = obj[key];
                result[key] = value;
                return result;
            },
            {}
        ));
    });
};

const canvasToDesign = async (canvasRef) => {
    return new Promise((resolve, reject) => {
        canvasRef.current.discardActiveObject().renderAll();
        let jsonFile = canvasRef.current.toJSON();
        const boundary = canvasRef.current.getObjects().find(i => i.objectType === 'boundary');
        if (!boundary) {
            return reject('No boundary found.');
        }

        let canvas = document.createElement("canvas");
        const gradCanvas = new fabric.Canvas(canvas, { width: canvasRef.current.getWidth(), height: canvasRef.current.getHeight() });
        gradCanvas.loadFromJSON(JSON.stringify(jsonFile), () => {
            gradCanvas.item(0).set({ selectable: false, stroke: 'transparent' });
            gradCanvas.renderAll();

            setTimeout(() => {
                resolve(gradCanvas.toDataURL({
                    format: 'png',
                    quality: 1,
                    multiplier: 300 / 96,
                    left: boundary.left,
                    top: boundary.top,
                    width: boundary.width,
                    height: boundary.height
                }));
            }, 1000);
        });
    });
};


const canvasToImage = async (canvasRef, template, bgImage, bgColor) => {
    return new Promise((resolve, reject) => {
        canvasRef.current.discardActiveObject().renderAll();
        const boundary = canvasRef.current.getObjects().find(i => i.objectType === 'boundary');
        if (!boundary) {
            return reject('No boundary found.');
        }

        const canvas = document.createElement("canvas");
        const gradCanvas = new fabric.Canvas(canvas, { width: canvasRef.current.getWidth(), height: canvasRef.current.getHeight() });

        fabric.Image.fromURL(bgImage, (image) => {
            gradCanvas.setBackgroundImage(image, () => {
                gradCanvas.setBackgroundColor(bgColor);
                gradCanvas.renderAll();

                fabric.Image.fromURL(template, (image) => {
                    image.set({ left: boundary.left, top: boundary.top });
                    image.scaleToWidth(boundary.width);
                    image.scaleToHeight(boundary.height);
                    gradCanvas.add(image);
                    gradCanvas.renderAll();

                    setTimeout(() => {
                        resolve(gradCanvas.toDataURL({
                            format: 'png',
                            quality: 1,
                            multiplier: 1,
                        }));
                    }, 1000);
                }, {
                    crossOrigin: 'anonymous'
                });
            }, {
                crossOrigin: 'anonymous'
            });
        });
    });
};


const jsonToImage = async (jsonFile, stage, bgColor) => {
    return new Promise((resolve) => {
        delete jsonFile.clipPath
        let canvas = document.createElement("canvas");
        const gradCanvas = new fabric.Canvas(canvas, { width: stage.product_width, height: stage.product_height });
        gradCanvas.loadFromJSON(JSON.stringify(jsonFile), function () {
            gradCanvas.item(0).set({ selectable: false, stroke: 'transparent' })
            gradCanvas.renderAll();
        })

        fabric.Image.fromURL(stage.image, function (image) {
            gradCanvas.setBackgroundImage(image);
            gradCanvas.setBackgroundColor(bgColor)
            gradCanvas.renderAll();
        }, {
            crossOrigin: 'anonymous'
        })
        setTimeout(() => {
            resolve(gradCanvas.toDataURL({
                format: 'png',
                quality: 1,
                multiplier: 300 / 96
            }))
        }, 200)
    });
}

const setLoader = (val, text = null) => {
    state.processing_text = text || 'Processing...Please wait...';
    state.processing = val;
}

function hasImageOrText(canvas) {
    const allowedTypes = ['image', 'group', 'i-text', 'path', 'text'];
    return canvas?.getObjects().some(obj => allowedTypes.includes(obj.type));
}


function hasCanvasDesign(canvases) {
    let hasDesign = false;
    canvases.forEach(canvas => {
        if (hasImageOrText(canvas?.ref?.current)) {
            hasDesign = true;
        }
    });
    return hasDesign;
}

const ucwords = (str) => {
    if (!str || typeof str !== 'string') return str;
    return str.replace('_', ' ').toLowerCase().replace(/(^|\s)\S/g, function (char) {
        return char.toUpperCase();
    });
}

const localizeImage = async (url, type = 'localize', hasThumb = false) => {
    return new Promise((resolve, reject) => {
        JSONPostRequest({
            href: route('file.localize'),
            data: {
                image_url: url,
                type: type,
                thumb: hasThumb
            },
            onResult: (val) => resolve(val),
            onError: (val) => reject(val),
            onLoading: (val) => setLoader(val)
        })
    });
}

const navigate = (url, method = "GET") => {
    if (!url) return;
    try {
        router.visit(url, {
            method: method,
            onStart: () => setLoader(true),
            onFinish: () => setLoader(false)
        })
    } catch (error) {
        console.log('Err:navigate:' + error);
        console.log(`Err:navigate_URL:${url}|${method}`);
    }
}

const configsToImage = async (options) => {
    if (!options)
        return undefined;
    return new Promise((resolve, reject) => {
        const canvas = document.createElement("canvas");
        const gradCanvas = new fabric.StaticCanvas(canvas, { width: options?.product_width, height: options?.product_height });

        fabric.Image.fromURL(options?.image, function (image) {
            image.set({
                originX: 'center',
                originY: 'center',
            });
            image.scaleToWidth(options?.product_width);
            image.scaleToHeight(options?.product_height);
            gradCanvas.add(image)
            gradCanvas.centerObject(image)

            let editZone = new fabric.Rect(
                { objectType: 'boundary', hasRotatingPoint: false, rx: 0, ry: 0, top: options?.top, left: options?.left, width: options?.width, height: options?.height, fill: 'transparent', selectable: false, stroke: 'black', strokeWidth: 5, strokeDashArray: [5, 5] }
            );
            gradCanvas.add(editZone);

            const rectCenterX = editZone.left + editZone.width / 2;
            const rectCenterY = editZone.top + editZone.height / 2;
            let text = new fabric.Text('Design\nHere', { originX: 'center', textAlign: 'center', originY: 'center', fontFamily: 'Roboto' });
            text.scaleToWidth(editZone.getScaledWidth() - 70)
            text.set({
                left: rectCenterX,
                top: rectCenterY,
            })
            gradCanvas.add(text);
            gradCanvas.renderAll();
            setTimeout(() => {
                resolve(gradCanvas.toDataURL({
                    format: 'png',
                    quality: 0.5,
                }));
            }, 1000);
        })
    });
};

const adjustStage = (obj, newProductWidth, newProductHeight) => {
    const { width, height, top, left, aspect_ratio, product_width, product_height } = obj;

    // Calculate the change in product dimensions as a ratio
    const widthRatio = newProductWidth / product_width;
    const heightRatio = newProductHeight / product_height;

    // Apply the ratio to the original dimensions
    const newWidth = width * widthRatio;
    const newHeight = height * heightRatio;

    // Calculate new top and left positions
    const newTop = top * heightRatio;
    const newLeft = left * widthRatio;

    // Adjust the dimensions based on aspect ratio
    const adjustedWidth = newHeight * aspect_ratio <= newWidth ? newHeight * aspect_ratio : newWidth;
    const adjustedHeight = adjustedWidth / aspect_ratio;

    return {
        ...obj,
        width: adjustedWidth,
        height: adjustedHeight,
        top: newTop,
        left: newLeft,
        product_width: newProductWidth,
        product_height: newProductHeight
    };
}
export {
    canvasesToJson, canvasToDesign, canvasToImage, canvasToMockupPreview, cn, configsToImage, generateId, hasCanvasDesign, hasImageOrText, jsonToImage, lang, localizeImage, navigate, setLoader, stripslashes, toast,
    ucwords, adjustStage
};

