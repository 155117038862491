import { proxy } from 'valtio'

const state = proxy({
    processing: false,
    processing_text: "Processing...Please wait...",
    intro: true,
    colors: ['#ccc', '#EFBD4E', '#80C670', '#726DE8', '#EF674E', '#353934'],
    decals: ['react', 'three2', 'pmndrs'],
    color: '#80C670',
    decal: 'three2',
    setProcessing(val) {
        this.processing = val
    }
})

export { state }
